import { Entity } from "@backstage/catalog-model";
import { ConfigApi, createApiRef, FetchApi,IdentityApi } from "@backstage/core-plugin-api";
import { SelectItem } from "@backstage/core-components";



export interface IVIDPClusterHealthService{
    getVIDPClusterHealth(entity: Entity, opts:any): Promise<SelectItem[]>;
}

export const vidpCLusterHealthApiRef = createApiRef<VIDPClusterHealthService>({
    id: 'plugin.argohealth.vidphealthservice',
  });

export class VIDPClusterHealthService implements IVIDPClusterHealthService {
    
    public readonly backendUrl: string;
    public readonly frontendUrl: string;
    private readonly fetchApi: FetchApi;
    private identityApi:IdentityApi;

    
    public static GitHubProjectSlugAnnotationKey: string = "github.com/project-slug";

    constructor(options: {config: ConfigApi; fetchApi: FetchApi; identityApi:IdentityApi}) {
        this.fetchApi = options.fetchApi;  
        this.backendUrl = options.config.getString("backend.baseUrl");
        this.frontendUrl = options.config.getString("app.baseUrl");
        this.identityApi = options.identityApi;
    }

    async getToken(){
        const {token} = await this.identityApi.getCredentials();
        return token
    }
    
    async getVIDPClusterHealth(entity: Entity, abrtOpts:any): Promise<any> {

        const url = `${this.backendUrl}/api/proxy/consumerservice/GetClusterStatus`;
        
         try{
            const {token} = await this.identityApi.getCredentials();

            const response = await this.fetchApi.fetch(url, {
                    method: "POST", 
                    body: JSON.stringify(entity), 
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                    signal: abrtOpts.signal
                });
                
                if(!response.ok){
                    return {message:` VIDP Cluster Health data not available for ${entity.metadata?.name??"entity"}`};
                }

                const data: any = await response.json();
                return data
           
        }catch(e){
            console.log(`Could not able to fetch VIDP Cluster health data ${e}`)
        }
    }
}